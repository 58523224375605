export const DISPLAYNAMES_DISPLAYNAME = 1;
export const DISPLAYNAMES_BOTH = 2;
export const DISPLAYNAMES_USERNAME = 3;
export const READMORE_STYLE_COMPACT = 'expandable';
export const READMORE_STYLE_COMFORT = 'modern';

// Comment hide types
export const COMMENT_VISIBLE = 0;
export const COMMENT_DELETED = 1;
export const COMMENT_HIDDEN_BANNED = 2;
export const COMMENT_HIDDEN_ARCHIVED = 3;
