export const postReadmoreConfig = {
  450: {
    maxLines: 11,
    aboveFoldLines: 8,
  },
  default: {
    maxLines: 8,
    aboveFoldLines: 5,
  },
};

export const commentReadmoreConfig = {
  450: {
    maxLines: 11,
    aboveFoldLines: 8,
  },
  default: {
    maxLines: 8,
    aboveFoldLines: 5,
  },
};
